import React from 'react';

import Layout, {
    bgImageDefault,
    Col3,
    PageHeaderA,
    PageSection,
    Ul,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { Checklist } from '../components/checklist';
import { ContactSection } from '../components/contact';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { makeSectionId } from '../components/jumpboxlink';
import { ServiceJumpTable } from '../components/servicejumptable';
import Seo from '../components/seo';

function SecurityScanServices({ data, location }) {
    const { t } = useTranslation();
    const overview = [
        {
            title: t('external_scan_title', 'Externer Schwachstellenscan'),
            desc: t(
                'external_scan_desc',
                'Einfallstore von außen identifizieren'
            ),
        },
        {
            title: t('internal_scan_title', 'Interner Schwachstellenscan'),
            desc: t('internal_scan_desc', 'Ausbreitung im Netzwerk verhindern'),
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.platform}
            headerContent={
                <PageHeaderA
                    title={
                        <Trans i18nKey="title">Security Scan Services</Trans>
                    }
                    text={t(
                        'subtitle',
                        'Vorhandene Schwachstellen erkennen, noch bevor ein Angreifer es tut.'
                    )}
                    image={
                        <StaticImage
                            src="../images/headers/datacenter.png"
                            alt="Security Scan Services"
                            loading="eager"
                            objectFit="cover"
                            placeholder="none"
                            className="aspect-video"
                            width={500}
                        />
                    }
                />
            }
        >
            <Seo title={t('title', 'Security Scan Services')} />
            <PageSection size="big">
                <PageSubtitle className="max-w-3xl mt-4">
                    <Trans i18nKey="intro_1">
                        Fast jedes System von ausreichender Komplexität enthält
                        auch Schwachstellen. Die&nbsp;Frage ist nur, wer findet
                        sie zuerst?
                    </Trans>
                </PageSubtitle>
                <PageText>
                    <Trans i18nKey="intro_2">
                        Die condignum Security Scan Services ermöglichen es
                        Ihnen einfach und selbständig Security Scans
                        durchzuführen um vorhandene Schwachstellen in Ihren
                        Systemen aufzudecken und Angreifern stets einen Schritt
                        voraus zu sein.
                        <br />
                        In Kombination mit dem condignum Vulnerability
                        Management wird auch die Behandlung und Nachhaltung der
                        Schwachstellen zum Kinderspiel.
                    </Trans>
                </PageText>
            </PageSection>

            <ServiceJumpTable items={overview} type="platform" />

            <PageSection size="big" id={makeSectionId(overview[0].title)}>
                <header>
                    <PageTitle>
                        <Trans i18nKey="external_scan_title">
                            Externer Schwachstellenscan
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="external_scan_desc">
                            Einfallstore von außen identifizieren
                        </Trans>
                    </PageSubtitle>
                </header>
                <PageText>
                    <Trans i18nKey="external_scan_col1">
                        Mit nur wenigen Klicks können Security Scans
                        gestartet werden um die Angriffsfläche aus dem
                        Internet zu analysieren.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="external_scan_col2">
                        Schwachstellen in Webanwendungen und Systemen werden
                        identifiziert und können geschlossen werden, noch
                        bevor Angreifer sie missbrauchen.
                    </Trans>
                </PageText>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'external_scan_col3_1',
                            'Professionelle Security Scans ohne aufwendiges Setup'
                        ),
                        t(
                            'external_scan_col3_2',
                            'On-Demand sowie automatisierte regelmäßige Durchführung'
                        ),
                        t(
                            'external_scan_col3_3',
                            'Überblick der Angriffsvektoren aus dem Internet'
                        ),
                        t(
                            'external_scan_col3_4',
                            'Schwachstellenüberblick mit technischer Risikobewertung'
                        ),
                    ]}
                />
            </PageSection>

            <PageSection size="big" id={makeSectionId(overview[1].title)} isDark >
                <header>
                    <PageTitle>
                        <Trans i18nKey="internal_scan_title">
                            Interner Schwachstellenscan
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="internal_scan_desc">
                            Ausbreitung im Netzwerk verhindern
                        </Trans>
                    </PageSubtitle>
                </header>
                <PageText>
                        <Trans i18nKey="internal_scan_col1">
                            Mit nur minimalem Setup können professionelle
                            Security Scans im internen Netzwerk durchgeführt
                            werden um die interne Angriffsfläche sowie die
                            Ausbreitungsmöglichkeiten für Angreifer zu
                            analysieren.
                        </Trans>
                    </PageText>
                        <PageText>
                        <Trans i18nKey="internal_scan_col2">
                            Schwachstellen in der Systemlandschaft werden
                            identifiziert und können geschlossen werden, noch
                            bevor Angreifer sie missbrauchen.
                        </Trans>
                    </PageText>
                    <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'internal_scan_col3_1',
                            'Professionelle Security Scans mit minimalem Setup'
                        ),
                        t(
                            'internal_scan_col3_2',
                            'On-Demand sowie automatisierte regelmäßige Durchführung'
                        ),
                        t(
                            'internal_scan_col3_3',
                            'Überblick der Angriffsvektoren aus dem Internet'
                        ),
                        t(
                            'internal_scan_col3_4',
                            'Schwachstellenüberblick mit technischer Risikobewertung'
                        ),
                    ]}
                />
            </PageSection>

            <ContactSection isDark={false} />
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["security-scan-services"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default SecurityScanServices;
